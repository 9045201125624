import React, { useEffect, useState } from 'react';
import 'fomantic-ui-css/semantic.min.css';
import {
  Header,
  Icon,
  Grid,
  GridRow,
  GridColumn,
  Segment,
  Button,
  Message,
  Accordion,
  Divider,
  Loader
} from 'semantic-ui-react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';

const WordPressSocials = () => {
  const [dateFields, setDateFields] = useState([]);
  const [socialDescription, setSocialDescription] = useState('');
  const [editor1Content, setEditor1Content] = useState('');
  const [editor1ExtraContent, setEditor1ExtraContent] = useState('');
  const [editor2Content, setEditor2Content] = useState('');
  const [editor2ExtraContent, setEditor2ExtraContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://latindancecanada.com/wp-json/socials-2/v1/date-fields');
        const { date_fields, social_description, editor_1_content, editor_1_extra_content, editor_2_content, editor_2_extra_content } = response.data;

        setDateFields(date_fields);
        setSocialDescription(social_description);
        setEditor1Content(editor_1_content);
        setEditor1ExtraContent(editor_1_extra_content);
        setEditor2Content(editor_2_content);
        setEditor2ExtraContent(editor_2_extra_content);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching WordPress data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const cleanSocialDescription = (description) => {
    return description.replace(/\[display-pay-link-2\]/g, '');
  };

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const formatDate = (dateString) => {
    const options = { month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const removePTags = (htmlString) => {
    return htmlString.replace(/<\/?p>/g, ''); // Remove opening and closing <p> tags
  };

  const renderDateFields = () => {
    const earliestDateFields = dateFields
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .slice(0, 4);

    return earliestDateFields.map((entry, index) => {
      const formattedDate = formatDate(entry.date);

      return (
        <>
          {index === 0 && <Header size='small'>Next Social</Header>}
          {index === 1 && <Header size='small'>Future Socials</Header>}
          <Segment key={index}>
            <div className="section-item socials">
              <Header size='medium'>{entry.date}</Header>
              <div className="location" style={{ marginBottom: '1rem' }}>
                {entry.radio_button === 'location2' && (
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: editor2Content }} />
                    <a href={removePTags(editor2ExtraContent)} target='_blank' rel='noopener noreferrer'> Google Map</a>
                  </span>
                )}
                {entry.radio_button === 'location1' && (
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: editor1Content }} />
                    <a href={removePTags(editor1ExtraContent)} target='_blank' rel='noopener noreferrer'> Google Map</a>
                  </span>
                )}
              </div>
              {index === 0 && (
                <>
                  <Button className="big bottom-margin" fluid color='green' href='https://latindancecanada.com/socials-web-app/' rel='noopener noreferrer'>
                    Pay in advance for {formattedDate}
                  </Button>
                  {isMobile && (
                    <>
                      <Accordion className='social-accordion'>
                        <Accordion.Title
                          active={activeIndex === index}
                          index={index}
                          onClick={() => handleAccordionClick(index)}
                        >
                          <Icon name={activeIndex === index ? 'chevron up' : 'chevron down'} />
                          What you can look forward to
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === index}>
                          <div dangerouslySetInnerHTML={{ __html: cleanSocialDescription(socialDescription) }} />
                        </Accordion.Content>
                      </Accordion>
                    </>
                  )}
                </>
              )}
            </div>
          </Segment>
        </>
      );
    });
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn mobile={16} tablet={8} computer={8}>
          {isLoading ? (
            <>
              <Divider hidden />
              <Loader active inline='centered' />
              <Divider hidden />
            </>
          ) : (
            dateFields.length === 0 ? (
              <Message warning>
                <Message.Header>Sorry, there are no upcoming socials.</Message.Header>
                <p>Please check back later.</p>
              </Message>
            ) : (
              renderDateFields()
            )
          )}
          {!isMobile && (
            <>
              <Divider hidden />
            </>
          )}
        </GridColumn>
        <GridColumn mobile={16} tablet={8} computer={8}>
          {!isMobile && (
            <>
              <Header size='small'>What you can look forward to</Header>
              <div dangerouslySetInnerHTML={{ __html: cleanSocialDescription(socialDescription) }} />
            </>
          )}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default WordPressSocials;