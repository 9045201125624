import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import {
  GridRow,
  GridColumn,
  Grid,
  Breadcrumb,
  Button,
  BreadcrumbSection,
  BreadcrumbDivider,
  Header,
  List,
  ListItem,
  ListIcon,
  ListContent,
  Divider,
  Accordion,
  Icon,
  Image,
  Loader
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import '../App.css';
import 'fomantic-ui-css/semantic.min.css';

const colors = ['red'];

const Bachata1 = () => {
  const [Bachata1Data, setBachata1Data] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://latindancecanada.com/wp-json/custom/v2/get_options');
        const classItems = response.data.class_reg_accordion_items || [];
        const Bachata1 = classItems.find(item => item.order === '1');
        setBachata1Data(Bachata1);
        setIsLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        console.error('Error fetching class data:', error);
        setIsLoading(false); // Set loading state to false in case of error
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {isMobile ? (
        <></>
      ) : (
        <section className="hero">
          <Image src="../assets/ldc-bg-2022.webp" fluid />
        </section>
      )}
      {!isMobile && (
        <section className="App-breadcrumb">
          <Grid padded>
            {colors.map((color) => (
              <GridRow color={color} key={color}>
                <GridColumn>
                  <Breadcrumb>
                    <BreadcrumbSection as={Link} to="/">Home</BreadcrumbSection>
                    <BreadcrumbDivider />
                    <BreadcrumbSection as={Link} to="/classes">Classes</BreadcrumbSection>
                    <BreadcrumbDivider />
                    <BreadcrumbSection active>
                      {Bachata1Data ? Bachata1Data.class_reg_custom_accordion_title : 'Bachata Newbies'}
                    </BreadcrumbSection>
                  </Breadcrumb>
                </GridColumn>
              </GridRow>
            ))}
          </Grid>
        </section>
      )}
      <section className="ui container">
        {isLoading ? ( // Render loader if loading
          <>
            <Divider hidden />
            <Loader active inline='centered' />
            <Divider hidden />
          </>
        ) : (
          <React.Fragment>
            {Bachata1Data ? (
              <Grid>
                <GridRow>
                  <GridColumn mobile={16} tablet={16} computer={16}>
                    <Header size='large'>{Bachata1Data.class_reg_custom_accordion_title}</Header>
                    <List>
                      <ListItem>
                        <ListIcon name='calendar alternate outline' />
                        <ListContent>
                          {Bachata1Data.date_field_1} - {Bachata1Data.date_field_2}
                        </ListContent>
                      </ListItem>
                      <ListItem>
                        <ListIcon name='clock' />
                        <ListContent>
                          {Bachata1Data.text_field_2}
                        </ListContent>
                      </ListItem>
                      <ListItem>
                        <ListIcon name='map marker alternate' />
                        <ListContent>Philippine Bayanihan Community Centre<br />
                          1709 Blanshard St.<br />
                          Victoria, BC<br />
                          <a href='https://www.google.com/maps/dir//Philippine+Bayanihan+Community+Centre,+1709+Blanshard+St,+Victoria,+BC+V8W+2J8/@48.4300274,-123.3637776,17z/data=!4m17!1m7!3m6!1s0x548f74842067c895:0xdf86a4aa9306c566!2sPhilippine+Bayanihan+Community+Centre!8m2!3d48.4296501!4d-123.3616533!16s%2Fg%2F1tg5bt7c!4m8!1m0!1m5!1m1!1s0x548f74842067c895:0xdf86a4aa9306c566!2m2!1d-123.3616316!2d48.4296487!3e3?entry=ttu' target='_blank' rel='noopener noreferrer'>Google Maps</a></ListContent>
                      </ListItem>
                      <ListItem>
                        <ListIcon name='dollar sign' />
                        <ListContent>Tuition for 6-weeks: $120</ListContent>
                      </ListItem>
                    </List>
                    <Divider />
                    <Accordion fluid>
                      <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleClick}
                      >
                        <Icon name='dropdown' />
                        Class Information
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 0}>
                        {Bachata1Data.editor_field && (
                          <p dangerouslySetInnerHTML={{ __html: Bachata1Data.editor_field }} />
                        )}
                      </Accordion.Content>
                    </Accordion>
                    <Divider />
                    <Accordion fluid>
                      <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleClick}
                      >
                        <Icon name='dropdown' />
                        General Information
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 1}>
                        <p>Dancers must register for the six-week series. There will be no drop-in option for these classes.</p>
                        <p>If you are sick, please stay home. If you are going to miss a class, send an email to latindanceclassesvictoria@gmail.com before the class. You can have one make-up class during the next series. It will be your responsibility to let me know in advance what class you plan to attend during the next series for your make-up class.</p>
                        <p>E-transfer: ronaldmartinezhca@gmail.com (If you don’t have a credit card, use the e-transfer email to register and send payment. Enter for the message "full name for bachata newbies")</p>
                      </Accordion.Content>
                    </Accordion>
                    <Divider />
                    <Button fluid size='huge' color='green' href='https://latindancecanada.com/group-classes/bachata-1-registration-web-app/' rel='noopener noreferrer'>Register Now</Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            ) : (
              <Grid>
                <GridRow>
                  <GridColumn mobile={16} tablet={16} computer={8}>
                    No data available for Bachata Newbies class.
                  </GridColumn>
                </GridRow>
              </Grid>
            )}
          </React.Fragment>
        )}
      </section>
    </Layout>
  );
};

export default Bachata1;